// funders
const fundersLogosDir = ''
const rssDir = '/Funders/RSS/'
export const FUNDERS = [
  {
    epmcFunders: true,

    name: 'The Academy of Medical Sciences',

    text: 'Academy of Medical Sciences',
    value: ['Academy of Medical Sciences'],

    // abbr: 'The Academy of Medical Sciences',
    abbr: 'Academy of Medical Sciences',

    imageUrl: fundersLogosDir + 'ams.svg',
    imageWidth: '257',

    url: '//www.acmedsci.ac.uk/',
    oaPolicy:
      '//www.acmedsci.ac.uk/grants-and-schemes/grant-schemes/information-for-awardees/policies-and-concordats-for-awardees/',

    rss: rssDir + 'AMS.xml',
  },
  {
    epmcFunders: true,

    name: 'RNID',

    text: 'RNID',
    value: ['RNID'],

    abbr: 'RNID',

    imageUrl: fundersLogosDir + 'rnid.svg',
    imageWidth: '82',

    url: '//rnid.org.uk/',
    oaPolicy:
      '//rnid.org.uk/wp-content/uploads/2020/11/OpenAccessPolicy2020.pdf',

    rss: rssDir + 'ACT.xml',
  },
  {
    epmcFunders: true,

    name: "Alzheimer's Society",

    text: "Alzheimer's Society",
    value: ["Alzheimer's Society"],

    abbr: "Alzheimer's Society",
    imageWidth: '138',

    imageUrl: fundersLogosDir + 'alzheimerssociety.png',

    url: '//www.alzheimers.org.uk/researchfunding/',
    oaPolicy:
      '//www.alzheimers.org.uk/sites/default/files/2020-10/AS_open-access-policy.pdf',

    rss: rssDir + 'ALZS.xml',
  },
  {
    epmcFunders: true,

    name: 'Austrian Science Fund FWF',

    text: 'Austrian Science Fund FWF',
    value: ['Austrian Science Fund FWF'],

    abbr: 'FWF',

    imageUrl: fundersLogosDir + 'fwf.png',
    imageWidth: '223',

    url: '//www.fwf.ac.at/en/',
    oaPolicy: '//www.fwf.ac.at/en/research-funding/open-access-policy/',

    rss: rssDir + 'FWF.xml',
  },
  //
  {
    epmcFunders: true,

    name: 'Biotechnology and Biological Sciences Research Council',

    text: 'Biotechnology and Biological Sciences Research Council',
    value: ['Biotechnology and Biological Sciences Research Council'],

    abbr: 'BBSRC',

    imageUrl: fundersLogosDir + 'bbsrc.svg',
    imageWidth: '236',

    url: '//www.bbsrc.ac.uk/',
    oaPolicy:
      '//bbsrc.ukri.org/about/policies-standards/access-research-outputs/',

    rss: rssDir + 'BBSRC.xml',
  },
  {
    epmcFunders: true,

    name: 'Blood Cancer UK',

    text: 'Blood Cancer UK',
    value: ['Blood Cancer UK'],

    abbr: 'Blood Cancer UK',

    imageUrl: fundersLogosDir + 'bloodwise.png',
    imageWidth: '79',

    url: '//bloodcancer.org.uk/',
    oaPolicy: '//bloodcancer.org.uk/research/open-access/',

    rss: rssDir + 'LLR.xml',
  },
  {
    epmcFunders: true,

    name: 'Breast Cancer Now',

    text: 'Breast Cancer Now',
    value: ['Breast Cancer Now'],

    abbr: 'Breast Cancer Now',

    imageUrl: fundersLogosDir + 'bcn.svg',
    imageWidth: '158',

    url: '//breastcancernow.org',
    oaPolicy:
      '//breastcancernow.org/breast-cancer-research/funding-for-researchers/how-we-award-grants/research-policies',

    rss: rssDir + 'BCN.xml',
  },
  {
    epmcFunders: true,

    name: 'British Heart Foundation',

    text: 'British Heart Foundation',
    value: ['British Heart Foundation'],

    abbr: 'BHF',

    imageUrl: fundersLogosDir + 'bhf.svg',
    imageWidth: '96',

    url: '//www.bhf.org.uk/',
    oaPolicy:
      '//www.bhf.org.uk/research/research-grants/managing-your-grant/open-access-policy.aspx',

    rss: rssDir + 'BHF.xml',
  },
  //
  {
    epmcFunders: true,

    name: 'Cancer Research UK',

    text: 'Cancer Research UK',
    value: ['Cancer Research UK'],

    abbr: 'CRUK',

    imageUrl: fundersLogosDir + 'cruk.svg',
    imageWidth: '215',

    url: '//www.cancerresearchuk.org/',
    oaPolicy:
      '//www.cancerresearchuk.org/science/funding/terms-conditions/funding-policies/policy-open-access/',

    rss: rssDir + 'CRUK.xml',
  },
  {
    epmcFunders: true,

    name: 'Chief Scientist Office',

    text: 'Chief Scientist Office',
    value: ['Chief Scientist Office'],

    abbr: 'CSO',

    imageUrl: fundersLogosDir + 'cso.svg',
    imageWidth: '209',

    url: '//www.sehd.scot.nhs.uk/cso/',
    oaPolicy: '//www.cso.scot.nhs.uk/outputs/cso-open-access-policy/',

    rss: rssDir + 'CSO.xml',
  },
  //
  {
    epmcFunders: true,

    name: 'National Institute for Health Research (NIHR)',

    text: 'National Institute for Health Research (NIHR)',
    value: ['National Institute for Health Research (NIHR)'],

    abbr: 'DH/NIHR',

    imageUrl: fundersLogosDir + 'nihr.svg',
    imageWidth: '260',

    url: '//www.nihr.ac.uk/',
    oaPolicy: '//www.nihr.ac.uk/documents/nihr-open-access-policy/28999',

    rss: rssDir + 'DH.xml',
  },
  {
    epmcFunders: true,

    name: 'Diabetes UK',

    text: 'Diabetes UK',
    value: ['Diabetes UK'],

    abbr: 'Diabetes UK',

    imageUrl: fundersLogosDir + 'diabetesuk.svg',
    imageWidth: '232',

    url: '//www.diabetes.org.uk/',
    oaPolicy:
      '//www.diabetes.org.uk/research/for-researchers/apply-for-a-grant/grant-conditions',

    rss: rssDir + 'DUK.xml',
  },
  {
    epmcFunders: true,

    name: 'The Dunhill Medical Trust',

    text: 'The Dunhill Medical Trust',
    value: ['The Dunhill Medical Trust'],

    abbr: 'The Dunhill Medical Trust',

    imageUrl: fundersLogosDir + 'dunhill.svg',
    imageWidth: '255',

    url: '//www.dunhillmedical.org.uk/',
    oaPolicy:
      '//dunhillmedical.org.uk/wp-content/uploads/2023/05/2020-09-Open-access-policy1.pdf',

    rss: rssDir + 'DMT.xml',
  },
  {
    epmcFunders: true,

    name: 'European & Developing Countries Clinical Trials Partnership (EDCTP)',

    text: 'European & Developing Countries Clinical Trials Partnership (EDCTP)',
    value: [
      'European & Developing Countries Clinical Trials Partnership (EDCTP)',
    ],

    abbr: 'EDCTP',

    imageUrl: fundersLogosDir + 'edctp2.png',
    imageWidth: '100',

    url: '//www.edctp.org/',
    oaPolicy:
      '//www.edctp.org/web/app/uploads/2022/02/EDCTP2_policy_on_registering_and_reporting_clinical_studies-v2.pdf',

    rss: rssDir + 'EDCTP.xml',
  },
  {
    epmcFunders: true,

    name: 'European Research Council',

    text: 'European Research Council',
    value: ['European Research Council'],

    abbr: 'ERC',

    imageUrl: fundersLogosDir + 'erc.svg',
    imageWidth: '136',

    url: '//erc.europa.eu/',
    oaPolicy:
      '//erc.europa.eu/sites/default/files/document/file/ERC_Open_Access_Guidelines-revised_Dec_2021.pdf',
    oaReq: '//erc.europa.eu/funding-and-grants/managing-project/open-access',
    other:
      'ERC funding towards Europe PMC is provided under <a href="//cordis.europa.eu/project/id/101034194" onclick="window.open(\'http://cordis.europa.eu/project/id/101034194\'); return false;">grant number 101034194 <i class="fas fa-external-link-alt"></i></a>',

    rss: rssDir + 'ERC.xml',
  },
  {
    epmcFunders: true,

    name: 'Health and Care Research Wales',

    text: 'Health and Care Research Wales',
    value: ['Health and Care Research Wales'],

    abbr: 'HCRW',

    imageUrl: fundersLogosDir + 'hcrw.png',
    imageWidth: '280',

    url: '//healthandcareresearchwales.org/',
    oaPolicy:
      '//healthandcareresearchwales.org/sites/default/files/2022-05/Health_and_Care_Research_Wales_Open_Access_Policy_2022_eng.pdf',
    rss: rssDir + 'HCRW.xml',
  },
  //
  {
    epmcFunders: true,

    name: 'Marie Curie',

    text: 'Marie Curie',
    value: ['Marie Curie'],

    abbr: 'Marie Curie',

    imageUrl: fundersLogosDir + 'mariecurie.png',
    imageWidth: '110',

    url: '//www.mariecurie.org.uk/',
    oaPolicy:
      '//www.mariecurie.org.uk/research/information-researchers/marie-curie-research-grants-scheme#openaccess',

    rss: rssDir + 'MCCC.xml',
  },
  {
    epmcFunders: true,

    name: 'Medical Research Council',

    text: 'Medical Research Council',
    value: ['Medical Research Council'],

    abbr: 'MRC',

    imageUrl: fundersLogosDir + 'mrc.png',
    imageWidth: '216',

    url: '//www.ukri.org/councils/mrc/',
    oaPolicy:
      '//www.ukri.org/about-us/mrc/our-policies-and-standards/research/open-access-policy/',

    rss: rssDir + 'MRC.xml',
  },
  {
    epmcFunders: true,

    name: 'Medical Research Foundation',

    text: 'Medical Research Foundation',
    value: ['Medical Research Foundation'],

    abbr: 'MRF',

    imageUrl: fundersLogosDir + 'mrf.png',
    imageWidth: '223',

    url: '//www.medicalresearchfoundation.org.uk/',
    oaPolicy:
      '//www.medicalresearchfoundation.org.uk/uploads/Medical-Research-Foundation-Open-Access-Policy.pdf',
    rss: rssDir + 'MRF.xml',
  },
  {
    epmcFunders: true,

    name: 'Motor Neurone Disease Association',

    text: 'Motor Neurone Disease Association',
    value: ['Motor Neurone Disease Association'],

    abbr: 'MND Association',

    imageUrl: fundersLogosDir + 'mnda.svg',
    imageWidth: '223',

    url: '//www.mndassociation.org/',
    oaPolicy: '//www.mndassociation.org/openaccess',

    rss: rssDir + 'MNDA.xml',
  },
  {
    epmcFunders: true,

    name: 'MQ: Transforming Mental Health',

    text: 'MQ: Transforming Mental Health',
    value: ['MQ: Transforming Mental Health'],

    abbr: 'MQ: Transforming Mental Health',

    imageUrl: fundersLogosDir + 'mq.png',
    imageWidth: '107',

    url: '//www.mqmentalhealth.org/',
    oaPolicy:
      '//www.mqmentalhealth.org/wp-content/uploads/2020-MQ-Open-Access-Policy.pdf',

    rss: rssDir + 'MQ.xml',
  },
  {
    epmcFunders: true,

    name: 'Multiple Sclerosis Society',

    text: 'Multiple Sclerosis Society',
    value: ['Multiple Sclerosis Society'],

    abbr: 'MS Society',

    imageUrl: fundersLogosDir + 'mssociety.svg',
    imageWidth: '158',

    url: '//www.mssociety.org.uk/',
    oaPolicy:
      '//www.mssociety.org.uk/sites/default/files/2021-02/MS%20Society%20Award%20Conditions%20and%20Policies%202020_0.pdf',

    rss: rssDir + 'MSS.xml',
  },
  //
  {
    epmcFunders: true,

    name: 'National Centre for the Replacement, Refinement and Reduction of Animals in Research',

    text: 'National Centre for the Replacement, Refinement and Reduction of Animals in Research',
    value: [
      'National Centre for the Replacement, Refinement and Reduction of Animals in Research',
    ],

    abbr: 'NC3Rs',

    imageUrl: fundersLogosDir + 'nc3rs.svg',
    imageWidth: '215',

    url: '//www.nc3rs.org.uk',
    oaPolicy:
      'https://www.ukri.org/publications/ukri-open-access-policy/uk-research-and-innovation-open-access-policy',

    rss: rssDir + 'NC3RS.xml',
  },
  //
  {
    epmcFunders: true,

    name: 'Dutch Research Council (NWO)',

    text: 'Dutch Research Council (NWO)',
    value: ['Dutch Research Council (NWO)'],

    abbr: 'NWO',

    imageUrl: fundersLogosDir + 'NWO.svg',
    imageWidth: '85',

    url: '//www.nwo.nl',
    oaPolicy: '//www.nwo.nl/en/open-access-publishing',

    rss: rssDir + 'NWO.xml',
  },
  {
    epmcFunders: true,

    name: 'Pancreatic Cancer UK',

    text: 'Pancreatic Cancer UK',
    value: ['Pancreatic Cancer UK'],

    abbr: 'Pancreatic Cancer UK',

    imageUrl: fundersLogosDir + 'pancreaticuk.svg',
    imageWidth: '150',

    url: '//www.pancreaticcancer.org.uk/',
    oaPolicy:
      '//www.pancreaticcancer.org.uk/research/our-policies-advisory-board/other-research-policies/',

    rss: rssDir + 'PANCREATICCANUK.xml',
  },
  {
    epmcFunders: true,

    name: "Parkinson's UK",

    text: "Parkinson's UK",
    value: ["Parkinson's UK"],

    abbr: "Parkinson's UK",

    imageUrl: fundersLogosDir + 'puk.svg',
    imageWidth: '212',

    url: '//www.parkinsons.org.uk/',
    oaPolicy:
      '//www.parkinsons.org.uk/content/open-access-publishing-researchers',

    rss: rssDir + 'PUK.xml',
  },
  //
  {
    epmcFunders: true,

    name: 'Swiss National Science Foundation',

    text: 'Swiss National Science Foundation',
    value: ['Swiss National Science Foundation'],

    abbr: 'Swiss National Science Foundation',

    imageUrl: fundersLogosDir + 'snsf.svg',
    imageWidth: '264',

    url: '//www.snf.ch/en/Pages/default.aspx',
    oaPolicy:
      '//www.snf.ch/en/researchinFocus/dossiers/open-science/Pages/default.aspx',

    rss: rssDir + 'SNSF.xml',
  },
  //
  {
    epmcFunders: true,

    name: 'Telethon Italy',

    text: 'Telethon',
    value: ['Telethon'],

    abbr: 'Telethon',

    imageUrl: fundersLogosDir + 'ti.svg',
    imageWidth: '160',

    url: '//www.telethon.it/en',
    oaPolicy:
      '//www.telethon.it/en/what-we-do/support-of-research-and-patients/support-to-research/open-access/open-access-policy',

    rss: rssDir + 'TI.xml',
  },
  //
  {
    epmcFunders: true,

    name: 'Versus Arthritis',

    text: 'Versus Arthritis',
    value: ['Versus Arthritis'],

    abbr: 'Versus Arthritis',

    imageUrl: fundersLogosDir + 'versusarthritis.svg',
    imageWidth: '155',

    url: '//www.versusarthritis.org/',
    oaPolicy:
      'https://versusarthritis.org/research/for-researchers/for-versus-arthritis-researchers/open-access-and-travel-support',

    rss: rssDir + 'ARC.xml',
  },
  //
  {
    epmcFunders: true,

    name: 'Wellcome Trust',

    text: 'Wellcome Trust',
    value: ['Wellcome Trust'],

    abbr: 'Wellcome Trust',

    imageUrl: fundersLogosDir + 'wellcome.svg',
    imageWidth: '100',

    url: '//www.wellcome.ac.uk/',
    oaPolicy:
      '//www.wellcome.ac.uk/About-us/Policy/Policy-and-position-statements/WTD002766.htm',

    rss: rssDir + 'WT.xml',
  },
  {
    epmcFunders: true,

    name: 'DBT/Wellcome Trust India Alliance',

    text: 'DBT/Wellcome Trust India Alliance',
    value: ['DBT/Wellcome Trust India Alliance'],

    // abbr: 'WT/DBT India Alliance',
    abbr: 'DBT/Wellcome Trust India Alliance',

    imageUrl: fundersLogosDir + 'indiaalliance.png',
    imageWidth: '252',

    url: '//www.indiaalliance.org/',
    oaPolicy: '//indiaalliance.org/applicants/award-policies',

    rss: rssDir + 'WTDBT.xml',
  },
  {
    epmcFunders: true,

    name: 'World Health Organization',

    text: 'World Health Organization',
    value: ['World Health Organization'],
	iarc: '(AFF:"International Agency for Research on Cancer" OR AFF:"Centre International de Recherche sur le Cancer" OR AFF:"IARC" OR AFF:"CIRC") AND (GRANT_ID:"001" AND GRANT_AGENCY:"World Health Organization")',

    abbr: 'WHO',

    imageUrl: fundersLogosDir + 'who.svg',
    imageWidth: '210',

    url: '//www.who.int/',
    oaPolicy: '//www.who.int/about/policies/publishing/open-access',

    rss: rssDir + 'WHO.xml',
  },
  {
    epmcFunders: true,

    name: 'Worldwide Cancer Research',

    text: 'Worldwide Cancer Research',
    value: ['Worldwide Cancer Research'],

    abbr: 'Worldwide Cancer Research',

    imageUrl: fundersLogosDir + 'wcr.svg',
    imageWidth: '162',

    url: '//www.worldwidecancerresearch.org/',
    oaPolicy:
      'https://www.worldwidecancerresearch.org/for-researchers/research-policies/our-open-access-publishing-policy',

    rss: rssDir + 'AICR.xml',
  },
  {
    epmcFunders: true,
    name: 'Yorkshire Cancer Research',

    text: 'Yorkshire Cancer Research',
    value: ['Yorkshire Cancer Research'],

    abbr: 'Yorkshire Cancer Research',

    imageUrl: fundersLogosDir + 'ycr.svg',
    imageWidth: '194',

    url: '//yorkshirecancerresearch.org.uk/',
    oaPolicy:
      '//yorkshirecancerresearch.org.uk/for-professionals/research/award-frequently-asked-questions?category=open-access-policy',

    rss: rssDir + 'YCR.xml',
  },
  {
    epmcFunders: true,

    name: 'ZonMw',

    text: 'ZonMw',
    value: ['ZonMw'],

    abbr: 'ZonMw',

    imageUrl: fundersLogosDir + 'ZonMw.png',
    imageWidth: '194',

    url: '//www.zonmw.nl/en',
    oaPolicy: '//www.zonmw.nl/en/research-and-results/open-access/',

    rss: rssDir + 'ZONMW.xml',
  },
  // covid-19 grant funders
  {
    name: 'Japan Agency for Medical Research and Development',

    text: 'Japan Agency for Medical Research and Development',
    value: ['Japan Agency for Medical Research and Development'],

    abbr: 'AMED Japan',

    imageUrl: fundersLogosDir + 'japanamed.svg',
    imageWidth: '118',

    url: '//www.amed.go.jp/en/index.html',
  },
  {
    name: 'The Australian Partnership for Preparedness Research on Infectious Diseases Emergencies',

    text: 'The Australian Partnership for Preparedness Research on Infectious Diseases Emergencies',
    value: ['APPRISE'],

    abbr: 'APPRISE',

    imageUrl: fundersLogosDir + 'apprise.svg',
    imageWidth: '190',

    url: '//www.apprise.org.au/',
  },
  {
    name: 'Bundesministerium für Bildung und Forschung',

    text: 'Bundesministerium für Bildung und Forschung',
    value: ['BMBF'],

    abbr: 'BMBF',
    imageWidth: '214',

    imageUrl: fundersLogosDir + 'bmbf.svg',

    url: '//www.bmbf.de/en/index.html',
  },
  {
    name: 'Coalition for Epidemic Preparedness Innovation',

    text: 'Coalition for Epidemic Preparedness Innovation',
    value: ['CEPI'],

    abbr: 'CEPI',

    imageUrl: fundersLogosDir + 'cepi.svg',
    imageWidth: '160',

    url: '//cepi.net/',
  },
  {
    name: 'Canadian Institutes of Health Research',

    text: 'Canadian Institutes of Health Research',
    value: ['Canadian Institutes of Health Research'],

    abbr: 'CIHR',

    imageUrl: fundersLogosDir + 'cihr.svg',
    imageWidth: '254',

    url: '//cihr-irsc.gc.ca/e/193.html',
  },
  {
    name: 'European Commission',

    text: 'European Commission',
    value: ['European Commission'],

    abbr: 'EC',

    imageUrl: fundersLogosDir + 'ec.svg',
    imageWidth: '214',

    url: '//ec.europa.eu/info/index_en',
  },
  {
    name: 'Emergent Ventures Fast Grants',

    text: 'Emergent Ventures Fast Grants',
    value: ['Emergent Ventures Fast Grants'],

    abbr: 'Emergent Ventures Fast Grants',

    imageUrl: fundersLogosDir + 'fastgrants.svg',
    imageWidth: '254',

    url: '//fastgrants.org/',
  },
  {
    name: 'Health Research Council of New Zealand',

    text: 'Health Research Council of New Zealand',
    value: ['Health Research Council of New Zealand'],

    abbr: 'HRCNZ',

    imageUrl: fundersLogosDir + 'hrcnz.svg',
    imageWidth: '180',

    url: '//www.hrc.govt.nz/',
  },
  {
    name: 'El Instituto de Salud Carlos III',

    text: 'El Instituto de Salud Carlos III',
    value: ['ISCIII'],

    abbr: 'ISCIII',

    imageUrl: fundersLogosDir + 'isciii.svg',
    imageWidth: '214',

    url: '//eng.isciii.es/eng.isciii.es/Paginas/Inicio.html',
  },
  {
    name: 'National Science Foundation',

    text: 'National Science Foundation',
    value: ['National Science Foundation'],

    abbr: 'NSF',

    imageUrl: fundersLogosDir + 'nsf.svg',
    imageWidth: '130',

    url: '//www.nsf.gov/',
  },
  {
    name: 'National Institutes of Health',

    text: 'National Institutes of Health',
    value: ['NIH'],

    abbr: 'NIH',

    imageUrl: fundersLogosDir + 'nih.png',
    imageWidth: '270',

    url: '//nih.gov/',
  },
  {
    name: 'REACTing',

    text: 'REACTing',
    value: ['REACTing'],

    abbr: 'REACTing/INSERM',

    imageUrl: fundersLogosDir + 'reactinginserm.svg',
    imageWidth: '240',

    url: '//reacting.inserm.fr/',
  },
  {
    name: 'UK Research and Innovation',

    text: 'UK Research and Innovation',
    value: ['UK Research and Innovation'],

    abbr: 'UKRI',

    imageUrl: fundersLogosDir + 'ukri.svg',
    imageWidth: '215',

    url: '//www.ukri.org/',
  },
  {
    name: 'Wellcome/Department for International Development',

    text: 'Wellcome/Department for International Development',
    value: ['Wellcome/Department for International Development'],

    abbr: 'Wellcome/DFID',

    imageUrl: fundersLogosDir + 'dfid.svg',
    imageWidth: '196',

    url: '//wellcome.ac.uk/grant-funding/schemes/epidemic-preparedness-covid-19',
  },
  {
    name: 'French National Agency for Research on Aids and Viral Hepatitis',

    text: 'French National Agency for Research on Aids and Viral Hepatitis',
    value: ['ANRS'],

    abbr: 'ANRS',

    imageUrl: fundersLogosDir + 'anrs.svg',
    imageWidth: '200',

    url: '//www.anrs.fr/en',
  },
  {
    name: 'Australian Government Medical Research Future Fund',

    text: 'Australian Government Medical Research Future Fund',
    value: ['Medical Research Future Fund'],

    abbr: 'MRFF',

    imageUrl: fundersLogosDir + 'mrff.svg',
    imageWidth: '264',

    url: '//www.health.gov.au/initiatives-and-programs/medical-research-future-fund',
  },
  {
    name: 'Enhancing Learning and Research for Humanitarian Assistance',

    text: 'Enhancing Learning and Research for Humanitarian Assistance',
    value: ['ELRHA'],

    abbr: 'Elrha',

    imageUrl: fundersLogosDir + 'elrha.svg',
    imageWidth: '186',

    url: '//www.elrha.org/',
  },
  {
    name: 'The British Academy',

    text: 'British Academy',
    value: ['British Academy'],

    abbr: 'British Academy',

    imageUrl: fundersLogosDir + 'britishacademy.svg',
    imageWidth: '338',

    url: '//www.thebritishacademy.ac.uk/',
  },
  {
    name: 'HRB Ireland/Irish Research Council',

    text: 'HRB Ireland/Irish Research Council',
    value: ['Health Research Board'],

    abbr: 'HRB Ireland/Irish Research Council',

    imageUrl: fundersLogosDir + 'hrb.png',
    imageWidth: '343',

    url: '//www.hrb.ie/',
  },
  {
    name: 'Swedish Research Council',

    text: 'Swedish Research Council',
    value: ['Swedish Research Council'],

    abbr: 'Swedish Research Council',

    imageUrl: fundersLogosDir + 'swedishresearchcouncil.png',
    imageWidth: '266',

    url: '//www.vr.se/english.html',
  },
  {
    name: 'Deutsche Forschungsgemeinschaft',

    text: 'Deutsche Forschungsgemeinschaft',
    value: ['Deutsche Forschungsgemeinschaft'],

    abbr: 'DFG',

    imageUrl: fundersLogosDir + 'dfg.png',
    imageWidth: '334',

    url: '//www.dfg.de/en/',
  },
  {
    name: 'Natural Sciences and Engineering Research Council of Canada',

    text: 'Natural Sciences and Engineering Research Council of Canada',
    value: ['NSERC Canada'],

    abbr: 'NSERC Canada',
  },
  {
    name: 'Robert Wood Johnson Foundation',

    text: 'Robert Wood Johnson Foundation',
    value: ['Robert Wood Johnson Foundation'],

    abbr: 'Robert Wood Johnson Foundation',
  },
  {
    name: 'Novo Nordisk Foundation',

    text: 'Novo Nordisk Foundation',
    value: ['Novo Nordisk Foundation'],

    abbr: 'Novo Nordisk Foundation',
  },
  {
    name: 'Royal Academy of Engineering',

    text: 'Royal Academy of Engineering',
    value: ['Royal Academy of Engineering'],

    abbr: 'RAENG',
  },
  {
    name: 'FAPERJ Brazil',

    text: 'FAPERJ Brazil',
    value: ['FAPERJ'],

    abbr: 'FAPERJ Brazil',
  },
  {
    name: 'Academy of Finland',

    text: 'Academy of Finland',
    value: ['Academy of Finland'],

    abbr: 'Academy of Finland',
  },
  {
    name: 'National Fund for Scientific Research (Belgium)',

    text: 'National Fund for Scientific Research (Belgium)',
    value: ['Fonds de la Recherche Scientifique (F.R.S.-FNRS Belgium)'],

    abbr: 'FNRS Belgium',
  },
  {
    name: 'National Science Center Poland',

    text: 'National Science Center Poland',
    value: ['National Science Center Poland'],

    abbr: 'National Science Center Poland',
  },
  {
    name: 'Danish Ministry of Higher Education and Science',

    text: 'Danish Ministry of Higher Education and Science',
    value: ['Danish Ministry of Higher Education and Science'],

    abbr: 'UFM Denmark',
  },
  {
    name: 'Danish Independent Research Foundation',

    text: 'Danish Independent Research Foundation',
    value: ['Danish Independent Research Foundation'],

    abbr: 'Independent Research Fund Denmark',
  },
  {
    name: 'Croatian Science Foundation',

    text: 'Croatian Science Foundation',
    value: ['Croatian Science Foundation'],

    abbr: 'HRZZ Croatia',
  },
  {
    name: 'Paul Ramsay Foundation (via APPRISE)',

    text: 'Paul Ramsay Foundation (via APPRISE)',
    value: ['Paul Ramsay Foundation'],

    abbr: 'Paul Ramsay Foundation (via APPRISE)',
  },
  {
    name: 'Roche',

    text: 'Roche',
    value: ['Roche'],

    abbr: 'Roche',
  },
  {
    name: 'Research Foundation - Flanders (FWO)',

    text: 'Research Foundation - Flanders (FWO)',
    value: ['Research Foundation - Flanders (FWO)'],

    abbr: 'FWO Belgium',
  },
  {
    name: 'ANR/Other Funders (France)',

    text: 'ANR/Other Funders (France)',
    value: ['ANR'],

    abbr: 'ANR/Other Funders (France)',
  },
  {
    name: 'IMI Innovative Medicines Initiative',

    text: 'IMI Innovative Medicines Initiative',
    value: ['EC (IMI)'],

    abbr: 'EC (IMI)',
  },
  {
    name: 'Other Funders (Canada)',

    text: 'Other Funders (Canada)',
    value: ['Other Funders (Canada)'],

    abbr: 'Other Funders (Canada)',
  },
  {
    name: 'Agence Nationale de la Recherche',

    text: 'Agence Nationale de la Recherche',
    value: ['Agence Nationale de la Recherche'],

    abbr: 'ANR',
  },
  {
    name: 'CONACYT Mexico',

    text: 'CONACYT Mexico',
    value: ['CONACYT Mexico'],

    abbr: 'CONACYT Mexico',
  },
  {
    name: 'European Open Science Cloud',

    text: 'European Open Science Cloud',
    value: ['EOSC'],

    abbr: 'EOSC',
  },
  {
    name: 'Science Foundation Ireland',

    text: 'Science Foundation Ireland',
    value: ['Science Foundation Ireland'],

    abbr: 'SFI Ireland',
  },
  {
    name: 'Ministry of Science, Technology and Innovation - Argentina',

    text: 'Ministry of Science, Technology and Innovation - Argentina',
    value: ['MINCyT'],

    abbr: 'MINCYT-Argentina',
  },
  {
    name: 'Science and Engineering Research Board (India)',

    text: 'Science and Engineering Research Board (India)',
    value: ['SERB India'],

    abbr: 'SERB India',
  },
  {
    name: 'National Medical Research Council Singapore',

    text: 'National Medical Research Council Singapore',
    value: ['National Medical Research Council Singapore'],

    abbr: 'National Medical Research Council Singapore',
  },
  {
    name: 'Other funders (France)',

    text: 'Other funders (France)',
    value: ['Other funders (France)'],

    abbr: 'Other funders (France)',
  },
  {
    name: 'The Research Council of Norway',

    text: 'The Research Council of Norway',
    value: ['Research Council of Norway'],

    abbr: 'RCN',
  },
  {
    name: 'Innovation Fund Denmark',

    text: 'Innovation Fund Denmark',
    value: ['Innovation Fund Denmark'],

    abbr: 'Innovationsfonden Denmark',
  },
  {
    name: 'National Council for Scientific Research (Lebanon)',

    text: 'National Council for Scientific Research (Lebanon)',
    value: ['CNRS-Lebanon'],

    abbr: 'CNRS Lebanon',
  },
  {
    name: 'APPRISE/CREID',

    text: 'APPRISE/CREID',
    value: ['APPRISE/CREID'],

    abbr: 'APPRISE/CREID',
  },
  {
    name: 'BPI-France',

    text: 'BPI-France',
    value: ['BPI-France'],

    abbr: 'BPI-France',
  },
  {
    name: 'Carlsberg Foundation',

    text: 'Carlsberg Foundation',
    value: ['Carlsberg Foundation'],

    abbr: 'Carlsberg Foundation',
  },
  {
    name: 'Wellcome Centre for Infectious Diseases Research in Africa',

    text: 'Wellcome Centre for Infectious Diseases Research in Africa',
    value: ['Wellcome Trust, CIDRI-Africa'],

    abbr: 'CIDRI-Africa',
  },
  {
    name: 'COVID-19 Therapeutics Accelerator (Wellcome/Bill and Melinda Gates Foundation)',

    text: 'COVID-19 Therapeutics Accelerator (Wellcome/Bill and Melinda Gates Foundation)',
    value: ['COVID-19 Therapeutics Accelerator'],

    abbr: 'COVID-19 Therapeutics Accelerator (Wellcome/Bill and Melinda Gates Foundation)',
  },
  {
    name: 'Coalition for Religious Equality and Inclusive Development',

    text: 'Coalition for Religious Equality and Inclusive Development',
    value: ['CREID'],

    abbr: 'CREID',
  },
  {
    name: 'Chan Zuckerberg Initiative',

    text: 'Chan Zuckerberg Initiative',
    value: ['Chan Zuckerberg Initiative'],

    abbr: 'CZI',
  },
  {
    name: 'DIM-ELICIT',

    text: 'DIM-ELICIT',
    value: ['DIM-ELICIT'],

    abbr: 'DIM-ELICIT',
  },
  {
    name: 'Estonian Research Council',

    text: 'Estonian Research Council',
    value: ['Estonian Research Council'],

    abbr: 'Estonian Research Council',
  },
  {
    name: 'São Paulo Research Foundation',

    text: 'São Paulo Research Foundation',
    value: ['FAPESP'],

    abbr: 'FAPESP',
  },
  {
    name: 'Foundation for Science and Technology Portugal',

    text: 'Foundation for Science and Technology Portugal',
    value: ['FCT Portugal'],

    abbr: 'FCT Portugal',
  },
  {
    name: 'Government of Ontario (Canada)',

    text: 'Government of Ontario (Canada)',
    value: ['Government of Ontario (Canada)'],

    abbr: 'Government of Ontario (Canada)',
  },
  {
    name: 'International Development Research Centre',

    text: 'International Development Research Centre',
    value: ['International Development Research Centre'],

    abbr: 'IDRC',
  },
  {
    name: 'Innosuisse',

    text: 'Innosuisse',
    value: ['Innosuisse'],

    abbr: 'Innosuisse',
  },
  {
    name: 'Lundbeck Foundation',

    text: 'Lundbeck Foundation',
    value: ['Lundbeck Foundation'],

    abbr: 'Lundbeck Foundation',
  },
  {
    name: 'MinScience - Colombia',

    text: 'MinScience - Colombia',
    value: ['MinScience'],

    abbr: 'MinScience - Colombia',
  },
  {
    name: 'NordForsk',

    text: 'NordForsk',
    value: ['NordForsk'],

    abbr: 'NordForsk',
  },
  {
    name: 'Federal Office of Public Health',

    text: 'Federal Office of Public Health',
    value: ['Federal Office of Public Health'],

    abbr: 'OFSP',
  },
  {
    name: 'Federal Food Safety and Veterinary Office',

    text: 'Federal Food Safety and Veterinary Office',
    value: ['Federal Food Safety and Veterinary Office'],

    abbr: 'OSAV',
  },
  {
    name: 'RCN/Trond-Mohn Foundation',

    text: 'RCN/Trond-Mohn Foundation',
    value: ['RCN/Trond-Mohn Foundation'],

    abbr: 'RCN/Trond-Mohn Foundation',
  },
  {
    name: 'SFI Ireland/IDA Ireland/Enterprise Ireland',

    text: 'SFI Ireland/IDA Ireland/Enterprise Ireland',
    value: ['SFI Ireland/IDA Ireland/Enterprise Ireland'],

    abbr: 'SFI Ireland/IDA Ireland/Enterprise Ireland',
  },
  {
    name: 'Snow Medical (via CREID/APPRISE)',

    text: 'Snow Medical (via CREID/APPRISE)',
    value: ['Snow Medical'],

    abbr: 'Snow Medical (via CREID/APPRISE)',
  },
  {
    name: 'UNITAID/ANRS',

    text: 'UNITAID/ANRS',
    value: ['UNITAID/ANRS'],

    abbr: 'UNITAID/ANRS',
  },
  {
    name: 'UNITAID/EDCTP',

    text: 'UNITAID/EDCTP',
    value: ['UNITAID/EDCTP'],

    abbr: 'UNITAID/EDCTP',
  },
  {
    name: 'Victoria State Government (Australia)',

    text: 'Victoria State Government (Australia)',
    value: ['Victoria State Government'],

    abbr: 'Victoria State Government (Australia)',
  },
  {
    name: 'Volkswagen Foundation',

    text: 'Volkswagen Foundation',
    value: ['Volkswagen Stiftung'],

    abbr: 'Volkswagen Stiftung',
  },
  {
    name: 'Fondation pour la Recherche Médicale',

    text: 'Fondation pour la Recherche Médicale',
    value: ['Fondation pour la Recherche Médicale'],

    abbr: 'Fondation pour la Recherche Médicale',
  },
  {
    name: 'Research Nova Scotia',

    text: 'Research Nova Scotia',
    value: ['Research Nova Scotia'],

    abbr: 'Research Nova Scotia',
  },
  {
    name: 'African Academy of Sciences',

    text: 'African Academy of Sciences',
    value: ['African Academy of Sciences'],

    abbr: 'African Academy of Sciences',
  },
  {
    name: 'Agency for Healthcare Research and Quality',

    text: 'Agency for Healthcare Research and Quality',
    value: ['Agency for Healthcare Research and Quality'],

    abbr: 'Agency for Healthcare Research and Quality',
  },
  {
    name: 'Alberta Innovates',

    text: 'Alberta Innovates',
    value: ['Alberta Innovates'],

    abbr: 'Alberta Innovates',
  },
  {
    name: 'Animal Free Research UK',

    text: 'Animal Free Research UK',
    value: ['Animal Free Research UK'],

    abbr: 'Animal Free Research UK',
  },
  {
    name: 'ARAMIS',

    text: 'ARAMIS',
    value: ['ARAMIS'],

    abbr: 'ARAMIS',
  },
  {
    name: 'AXA France',

    text: 'AXA France',
    value: ['AXA France'],

    abbr: 'AXA',
  },
  {
    name: 'BBVA Foundation (Spain)',

    text: 'BBVA Foundation (Spain)',
    value: ['BBVA Foundation'],

    abbr: 'BBVA Foundation (Spain)',
  },
  {
    name: 'Botnar Research Centre for Child Health',

    text: 'Botnar Research Centre for Child Health',
    value: ['Botnar Research Centre for Child Health'],

    abbr: 'Botnar Research Centre for Child Health',
  },
  {
    name: 'British Society for Antimicrobial Chemotherapy',

    text: 'British Society for Antimicrobial Chemotherapy',
    value: ['British Society for Antimicrobial Chemotherapy'],

    abbr: 'BSAC',
  },
  {
    name: 'CaixaImpulse',

    text: 'CaixaImpulse',
    value: ['CaixaImpulse'],

    abbr: 'CaixaImpulse',
  },
  {
    name: 'CONACYT - Paraguay',

    text: 'CONACYT - Paraguay',
    value: ['CONACYT - Paraguay'],

    abbr: 'CONACYT - Paraguay',
  },
  {
    name: 'CONCYTEC Peru',

    text: 'CONCYTEC Peru',
    value: ['CONCYTEC Peru'],

    abbr: 'CONCYTEC Peru',
  },
  {
    name: 'Chilean Economic Development Agency',

    text: 'Chilean Economic Development Agency',
    value: ['CORFO'],

    abbr: 'CORFO (Chile)',
  },
  {
    name: 'CRUE/Santander',

    text: 'CRUE/Santander',
    value: ['CRUE/Santander'],

    abbr: 'CRUE/Santander',
  },
  {
    name: 'Department for Environment, Food and Rural Affairs',

    text: 'Department for Environment, Food and Rural Affairs',
    value: ['Department for Environment, Food and Rural Affairs'],

    abbr: 'DEFRA',
  },
  {
    name: 'Departamento de Investigación postgrado e interaccion social (Bolivia)',

    text: 'Departamento de Investigación postgrado e interaccion social (Bolivia)',
    value: [
      'Departamento de Investigación postgrado e interaccion social (Bolivia)',
    ],

    abbr: 'Departamento de Investigación postgrado e interaccion social (Bolivia)',
  },
  {
    name: 'DPI - Universidade de Brasilia (Brazil)',

    text: 'DPI - Universidade de Brasilia (Brazil)',
    value: ['DPI - Universidade de Brasilia (Brazil)'],

    abbr: 'DPI - Universidade de Brasilia (Brazil)',
  },
  {
    name: 'FAPEAM Brazil',

    text: 'FAPEAM Brazil',
    value: ['FAPEAM'],

    abbr: 'FAPEAM Brazil',
  },
  {
    name: 'Maranhão Foundation for Scientific and Technological Research',

    text: 'Maranhão Foundation for Scientific and Technological Research',
    value: ['FAPEMA'],

    abbr: 'FAPEMA',
  },
  {
    name: 'FAPEPI Brazil',

    text: 'FAPEPI Brazil',
    value: ['FAPEPI'],

    abbr: 'FAPEPI Brazil',
  },
  {
    name: 'FAPERGS Brazil',

    text: 'FAPERGS Brazil',
    value: ['FAPERGS'],

    abbr: 'FAPERGS Brazil',
  },
  {
    name: 'FAPES Brazil',

    text: 'FAPES Brazil',
    value: ['FAPES'],

    abbr: 'FAPES Brazil',
  },
  {
    name: 'Vinnova',

    text: 'Vinnova',
    value: ['Vinnova'],

    abbr: 'Vinnova',
  },
  {
    name: 'Vancouver Coastal Health Research Institute',

    text: 'Vancouver Coastal Health Research Institute',
    value: ['Vancouver Coastal Health Research Institute'],

    abbr: 'Vancouver Coastal Health Research Institute',
  },
  {
    name: 'FAPESB Brazil',

    text: 'FAPESB Brazil',
    value: ['FAPESB'],

    abbr: 'FAPESB Brazil',
  },
  {
    name: 'University of Minnesota',

    text: 'University of Minnesota',
    value: ['University of Minnesota'],

    abbr: 'University of Minnesota',
  },
  {
    name: 'Universidad de los Llanos (Colombia)',

    text: 'Universidad de los Llanos (Colombia)',
    value: ['Universidad de los Llanos'],

    abbr: 'Universidad de los Llanos (Colombia)',
  },
  {
    name: 'Southeast Asia Engineering Education Development Network',

    text: 'Southeast Asia Engineering Education Development Network',
    value: ['Southeast Asia Engineering Education Development Network'],

    abbr: 'Southeast Asia Engineering Education Development Network',
  },
  {
    name: 'Sino-German Center for Research Promotion',

    text: 'Sino-German Center for Research Promotion',
    value: ['Sino-German Center for Research Promotion'],

    abbr: 'SGC',
  },
  {
    name: 'Swiss Federal Office of Energy',

    text: 'Swiss Federal Office of Energy',
    value: ['Swiss Federal Office of Energy'],

    abbr: 'SFOE (Switzerland)',
  },
  {
    name: 'SEPAR (Spain)',

    text: 'SEPAR (Spain)',
    value: ['SEPAR'],

    abbr: 'SEPAR (Spain)',
  },
  {
    name: 'SENACYT - Panama',

    text: 'SENACYT - Panama',
    value: ['SENACYT - Panama'],

    abbr: 'SENACYT - Panama',
  },
  {
    name: 'Saskatchewan Health Research Foundation',

    text: 'Saskatchewan Health Research Foundation',
    value: ['Saskatchewan Health Research Foundation'],

    abbr: 'Saskatchewan Health Research Foundation',
  },
  {
    name: 'Royal Society of Tropical Medicine and Hygiene',

    text: 'Royal Society of Tropical Medicine and Hygiene',
    value: ['Royal Society of Tropical Medicine and Hygiene'],

    abbr: 'RSTMH',
  },
  {
    name: 'Research Manitoba',

    text: 'Research Manitoba',
    value: ['Research Manitoba'],

    abbr: 'Research Manitoba',
  },
  {
    name: 'FAPESC Brazil',

    text: 'FAPESC Brazil',
    value: ['FAPESC'],

    abbr: 'FAPESC Brazil',
  },
  {
    name: 'Qatar National Research Fund',

    text: 'Qatar National Research Fund',
    value: ['Qatar National Research Fund'],

    abbr: 'Qatar National Research Fund',
  },
  {
    name: 'Peter Wall Institute for Advanced Studies',

    text: 'Peter Wall Institute for Advanced Studies',
    value: ['Peter Wall Institute for Advanced Studies'],

    abbr: 'Peter Wall Institute',
  },
  {
    name: 'Federal District Research Support Foundation (Brazil)',

    text: 'Federal District Research Support Foundation (Brazil)',
    value: ['FAPDF'],

    abbr: 'Federal District Research Support Foundation (Brazil)',
  },
  {
    name: 'Patient-Centered Outcomes Research Institute',

    text: 'Patient-Centered Outcomes Research Institute',
    value: ['Patient-Centered Outcomes Research Institute'],

    abbr: 'Patient-Centered Outcomes Research Institute',
  },
  {
    name: 'National Natural Science Foundation of China / Korea Research Foundation',

    text: 'National Natural Science Foundation of China / Korea Research Foundation',
    value: ['National Natural Science Foundation of China'],

    abbr: 'NSFC/NRF (Korea)',
  },
  {
    name: 'The Austrian Research Promotion Agency',

    text: 'The Austrian Research Promotion Agency',
    value: ['The Austrian Research Promotion Agency (FFG)'],

    abbr: 'FFG Austria',
  },
  {
    name: 'National Health and Medical Research Council',

    text: 'National Health and Medical Research Council',
    value: ['National Health and Medical Research Council'],

    abbr: 'NHMRC',
  },
  {
    name: 'New South Wales Government (Health)',

    text: 'New South Wales Government (Health)',
    value: ['NSW Health'],

    abbr: 'New South Wales Government (Health)',
  },
  {
    name: 'FORMAS',

    text: 'FORMAS',
    value: ['Svenska Forskningsrådet Formas'],

    abbr: 'FORMAS',
  },
  {
    name: 'NASA',

    text: 'NASA',
    value: ['NASA'],

    abbr: 'NASA',
  },
  {
    name: 'Ministre des Solidarités et de la Santé (France)',

    text: 'Ministre des Solidarités et de la Santé (France)',
    value: ['Ministre des Solidarités et de la Santé'],

    abbr: 'Ministre des Solidarités et de la Santé (France)',
  },
  {
    name: 'Fundacion Mutua Madrilena (Spain)',

    text: 'Fundacion Mutua Madrilena (Spain)',
    value: ['Fundación Mutua Madrileña'],

    abbr: 'Fundacion Mutua Madrilena (Spain)',
  },
  {
    name: 'Michael Smith Foundation for Health Research',

    text: 'Michael Smith Foundation for Health Research',
    value: ['Michael Smith Foundation for Health Research'],

    abbr: 'Michael Smith Foundation',
  },
  {
    name: 'Luxembourg National Research Fund',

    text: 'Luxembourg National Research Fund',
    value: ['Luxembourg National Research Fund'],

    abbr: 'Luxembourg National Research Fund',
  },
  {
    name: 'Regional Government of Castile and León - Ministry of Health',

    text: 'Regional Government of Castile and León - Ministry of Health',
    value: ['Junta de Castilla y Leon'],

    abbr: 'Junta de Castilla y Leon - Consejeria de Sanidad (Spain)',
  },
  {
    name: 'Gund Institute for Environment',

    text: 'Gund Institute for Environment',
    value: ['Gund Institute for Environment'],

    abbr: 'Gund Institute',
  },
  {
    name: 'Regional Government of Andalusia',

    text: 'Regional Government of Andalusia',
    value: ['Junta de Andalucia'],

    abbr: 'Junta de Andalucia',
  },
  {
    name: 'Israel Innovation Authority',

    text: 'Israel Innovation Authority',
    value: ['Israel Innovation Authority'],

    abbr: 'Israel Innovation Authority',
  },
  {
    name: 'Institut de recherche Robert-Sauvé en santé et en sécurité du travail',

    text: 'Institut de recherche Robert-Sauvé en santé et en sécurité du travail',
    value: ['IRSST'],

    abbr: 'IRSST',
  },
  {
    name: 'International Growth Centre',

    text: 'International Growth Centre',
    value: ['International Growth Centre'],

    abbr: 'International Growth Centre',
  },
  {
    name: 'Indian Council of Social Science Research',

    text: 'Indian Council of Social Science Research',
    value: ['Indian Council of Social Science Research'],

    abbr: 'Indian Council of Social Science Research',
  },
  {
    name: 'Innovations for Poverty Action/DFID',

    text: 'Innovations for Poverty Action/DFID',
    value: ['Innovations for Poverty Action'],

    abbr: 'Innovations for Poverty Action/DFID',
  },
  {
    name: 'Innovate Peru',

    text: 'Innovate Peru',
    value: ['Innovate Peru'],

    abbr: 'Innovate Peru',
  },
  {
    name: 'National Natural Science Foundation of China / Bulgaria National Science Fund',

    text: 'National Natural Science Foundation of China / Bulgaria National Science Fund',
    value: ['Bulgaria National Science Fund'],

    abbr: 'NSFC/BNSF',
  },
  {
    name: 'Social Sciences and Humanities Research Council',

    text: 'Social Sciences and Humanities Research Council',
    value: ['Social Sciences and Humanities Research Council'],

    abbr: 'SSHRC',
  },
  {
    name: 'Ministerio de Ciencia, Tecnología, Conocimiento e Innovación, Chile',

    text: 'Ministerio de Ciencia, Tecnología, Conocimiento e Innovación, Chile',
    value: ['MINCTCI'],

    abbr: 'MINCTCI - Chile',
  },
  {
    name: 'C3.ai Digital Transformation Institute',

    text: 'C3.ai Digital Transformation Institute',
    value: ['C3.ai'],

    abbr: 'C3.ai DTI',
  },
  {
    name: 'Vienna Science and Technology Fund',

    text: 'Vienna Science and Technology Fund',
    value: ['Vienna Science and Technology Fund'],

    abbr: 'WWTF Austria',
  },
  {
    name: 'FAPEMIG Brazil',

    text: 'FAPEMIG Brazil',
    value: ['FAPEMIG'],

    abbr: 'FAPEMIG',
  },
  {
    name: 'Cambridge Africa',

    text: 'Cambridge Africa',
    value: ['Cambridge Africa'],

    abbr: 'Cambridge Africa',
  },
  {
    name: 'Organisation internationale de la Francophonie',

    text: 'Organisation internationale de la Francophonie',
    value: ['Agence Universitaire de la Francophonie'],

    abbr: 'AUF',
  },
  {
    name: 'Fundação Araucária Brazil',

    text: 'Fundação Araucária Brazil',
    value: ['Fundação Araucária'],

    abbr: 'Fundação Araucária',
  },
  {
    name: 'FAPEG Brazil',

    text: 'FAPEG Brazil',
    value: ['FAPEG'],

    abbr: 'FAPEG',
  },
  {
    name: 'National Research Fund Kenya',

    text: 'National Research Fund Kenya',
    value: ['National Research Fund Kenya'],

    abbr: 'NRF Kenya',
  },
  {
    name: 'NSF Bulgaria',

    text: 'NSF Bulgaria',
    value: ['NSF Bulgaria'],

    abbr: 'NSF Bulgaria',
  },
  {
    name: 'BRICS STI Framework Programme',

    text: 'BRICS STI Framework Programme',
    value: ['BRICS STI Framework Programme'],

    abbr: 'BRICS',
  },
  {
    name: 'FACEPE Brazil',

    text: 'FACEPE Brazil',
    value: ['FACEPE'],

    abbr: 'FACEPE',
  },
  {
    name: 'e-ASIA Joint Research Program',

    text: 'e-ASIA Joint Research Program',
    value: ['e-ASIA Joint Research Program'],

    abbr: 'e-Asia JRP',
  },
  {
    name: 'Ministry of Health - Italy',

    text: 'Ministry of Health - Italy',
    value: ['Ministry of Health - Italy'],

    abbr: 'Ministry of Health - Italy',
  },
  {
    name: 'FUNDECT Brazil',

    text: 'FUNDECT Brazil',
    value: ['FUNDECT'],

    abbr: 'FUNDECT',
  },
  {
    name: 'Agence Française de Développement',

    text: 'Agence Française de Développement',
    value: ['Agence Française de Développement'],

    abbr: 'AFD',
  },
  {
    name: 'FUNCAP Brazil',

    text: 'FUNCAP Brazil',
    value: ['FUNCAP'],

    abbr: 'FUNCAP',
  },
  {
    name: 'Partnership for Economic Policy',

    text: 'Partnership for Economic Policy',
    value: ['Partnership for Economic Policy'],

    abbr: 'Partnership for Economic Policy',
  },
  {
    name: 'Mauritius Research and Innovation Council',

    text: 'Mauritius Research and Innovation Council',
    value: ['Mauritius Research and Innovation Council'],

    abbr: 'MRIC Mauritius',
  },
  {
    name: 'FAPESQ Brazil',

    text: 'FAPESQ Brazil',
    value: ['FAPESQ'],

    abbr: 'FAPESQ',
  },
  {
    name: 'Institut Pasteur',

    text: 'Institut Pasteur',
    value: ['Institut Pasteur'],

    abbr: 'Institut Pasteur',
  },
  {
    name: 'UNICEF',

    text: 'UNICEF',
    value: ['UNICEF'],

    abbr: 'UNICEF',
  },
  {
    name: 'Malta Council for Science and Technology',

    text: 'Malta Council for Science and Technology',
    value: ['Malta Council for Science and Technology'],

    abbr: 'Malta Council for Science and Technology',
  },
  {
    name: 'Indian Council of Medical Research',

    text: 'Indian Council of Medical Research',
    value: ['Indian Council of Medical Research'],

    abbr: 'ICMR',
  },
  {
    name: 'FAPEAP Brazil',

    text: 'FAPEAP Brazil',
    value: ['FAPEAP'],

    abbr: 'FAPEAP',
  },
]

export const EPMC_FUNDERS = FUNDERS.filter((funder) => funder.epmcFunders).sort(
  (a, b) =>
    a.text
      .replace(/^(The )/, '')
      .toLowerCase()
      .localeCompare(b.text.replace(/^(The )/, '').toLowerCase())
)
